/* You can add global styles to this file, and also import other style files */
/* @font-face {
  font-family: 'Poppins'; 
  src:
    url('./assets/fonts/Poppins-Regular.ttf'),
    url('./assets/fonts/Poppins-Italic.ttf'),
    url('./assets/fonts/Poppins-Bold.ttf'),
    url('./assets/fonts/Poppins-BoldItalic.ttf'),
    url('./assets/fonts/Poppins-Black.ttf'),
    url('./assets/fonts/Poppins-BlackItalic.ttf'),
    url('./assets/fonts/Poppins-ExtraBold.ttf'),
    url('./assets/fonts/Poppins-ExtraBoldItalic.ttf'),
    url('./assets/fonts/Poppins-ExtraLight.ttf'),
    url('./assets/fonts/Poppins-ExtraLightItalic.ttf'),
    url('./assets/fonts/Poppins-Light.ttf'),
    url('./assets/fonts/Poppins-LightItalic.ttf'),
    url('./assets/fonts/Poppins-Medium.ttf'),
    url('./assets/fonts/Poppins-MediumItalic.ttf'),
    url('./assets/fonts/Poppins-SemiBold.ttf'),
    url('./assets/fonts/Poppins-SemiBoldItalic.ttf'),
    url('./assets/fonts/Poppins-Thin.ttf'),
    url('./assets/fonts/Poppins-ThinItalic.ttf');
  font-weight: normal;
  font-style: normal;
} */
@font-face {
  font-family: 'Helvetica', 'Arial', sans-serif; 
  font-weight: normal;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --dark-grey: #131313;
  --grey: #768BA0;
  --light-grey: #98A2B3;
  --purple: #5F28F4;
  --light-beige: #F7F7F7;
  --orange: #d18521;
  --green: #21d121;
  --red: #d12721;
}

html {
  margin: 0px;
  padding: 0px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
}

section.external {
  width: 100vw;
  height: auto;
  min-height: 100vh;
  background-color: black;
  position: relative;
}

section.external-white {
  width: 100vw;
  background-color: white;
  position: relative;
  padding-bottom: 100px;
}

header {
  height: 70px;
  padding: 10px;
  width: 100%;
  color: white;
}

header img.logo {
  height: 45px;
}

footer {
  color: var(--grey);
  position: fixed;
  height: 50px;
  padding: 10px;
  bottom: 0px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));;
}
footer .links{
  text-align: end;
}
footer .links a {
  padding: 5px;
  text-decoration: none;
}

main {
  width: 100%;
  display: grid;
  place-content: center;
  height: -webkit-fill-available;
}

main.searchScreen {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

main input[type=text], select,
  input[type=email], input[type=date] {
  padding: 1rem;
  border-radius: 0.5rem;
  color: black;
}

.athleteListItem {
  border: solid thin var(--light-grey);
  border-radius: 5px;
  color: white;
  padding: 10px;
  background-color: var(--dark-grey);
  text-transform: capitalize;
}

.athleteListItem .athleteDetails {
  margin: 0.75rem 0px;
}
.athleteListItem .athleteDetails li {
  margin: 0.25rem 0px;
}
.athleteListItem span::before {
  margin-right: 5px;
}
.athleteListItem span.education::before {
  content: url("./assets/icons/educationIcon.svg");
}
.athleteListItem span.height::before {
  content: url("./assets/icons/height.svg");
}
.athleteListItem span.weight::before {
  content: url("./assets/icons/weight.svg");
}
.athleteListItem span.primarySport::before {
  content: url("./assets/icons/priSport.svg");
}
.athleteListItem .initial {
  background-color: black;
  border-radius: 5px;
  display: grid;
  place-content: center;
  padding: 10px;
  font-size: 5em;
  color: white;
  text-transform: uppercase;
}
button.bigCTA {
  background-color: var(--purple);
  color: white;
  font-weight: 400;
  padding: 15px;
  border-radius: 30px;
  margin: 1rem 0px;
}

.centerForm {
  width: 50vw;
  padding: 2rem;
  background: white;
  text-align: center;
  margin: 2rem;
  max-width: 800px;
}

.centerForm h1 {
  font-weight: bold;
  font-size: 30px;
  line-height: 38px;
  margin: 0.5rem 0px;
}

.centerForm h3.subtitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  margin: 0.5rem 0px;
}

.centerForm input {
  width: 100%;
  border-radius: 8px;
  border: thin solid var(--light-grey);
  padding: 1rem 0.5rem;
  margin: 0.5rem auto;
}

.centerForm button, .successButton {
  /* width: 100%; */
  border-radius: 8px;
  padding: 1rem 0.5rem;
  margin: 1rem auto;
  background: var(--purple);
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

@media screen and (max-width: 768px) {
  .centerForm {
    width: 85vw;
    max-width: 100vw;
    margin: inherit auto;
  }
}

.multiselect-item-checkbox {
  text-transform: capitalize;
}